html,
* {
  -webkit-user-select: text !important;
  user-select: text !important;
}
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

#calendar {
  max-width: 1100px;
  margin: 40px auto;
}
.fc-event {
  user-select: text !important;
  -webkit-user-select: text !important;
}
.fc-event-title {
  margin: 1px;
}

.fc-event-time {
  margin: 1px;
  font-size: 0.9em;
  width: 3em;
}
.dialogboard {
  z-index: 10000;
}

td {
  white-space: pre-wrap !important
}
.fc-event-time, .fc-event-title {
  padding: 0 1px !important;
  white-space: pre-wrap !important;
}

.fc-title {
  white-space: pre-wrap !important;
}

.overlayAlert {
  z-index: 20000;
}